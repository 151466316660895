.block-accordions {
  .accordion-item {
    border-bottom: none;
    border-top: none;
    margin-bottom: .5rem;

    &:first-of-type {
      .accordion-button {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &:last-of-type {
      .accordion-button {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .accordion-body {
      border-width: 2px;
      border-style: solid;
      border-top-width: 0;
    }

    .accordion-button {
      border-width: 2px;
      border-style: solid;
      border-color: transparent;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &:focus {
        box-shadow: none;
        -moz-box-shadow: none;
      }

      &:not(.collapsed) {
        border-bottom-width: 0;
      }
    }
  }
}
