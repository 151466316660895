/* LIGHTBOX STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.lightbox-modal {
  .modal-body {
    display: flex;
    align-items: center;
    padding: 0;
    text-align: center;
  }

  .modal-content {
    background: var(--lightbox);
  }

  img {
    width: auto;
    max-height: 100vh;
    max-width: 100%;
  }

  .btn-close {
    position: absolute;
    top: 20px;
    right: 18px;
    font-size: 1.2rem;
    z-index: 10;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: $primary-turquoise;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);

    @include media-breakpoint-down(md) {
      width: 2rem;
      height: 2rem;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      @include media-breakpoint-down(md) {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .carousel-control-prev {
    left: 15px;
  }

  .carousel-control-next {
    right: 15px;
  }

  .btn-close-white {
    opacity: 1;
    filter: brightness(0) invert(1);
  }
}
