footer {
  .footer {
    min-height: 150px;

    .social-icon {
      height: 60px;
      width: auto;
    }

    .certifications {
      img,
      svg {
        height: 100px;
        max-width: 100%;
      }
    }

    a {
      &:not(.btn) {
        color: white;

        &:hover {
          color: $primary-turquoise;
        }
      }
    }
  }

  .sub-footer {
    @include font-size($font-size-base * .72);
  }
}
