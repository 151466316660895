.fui-form-container {
    .fui-legend {
        @include font-size($font-size-base);
        line-height: $line-height-base;
        margin-bottom: 0.75rem;
        font-weight: $font-weight-bold;
    }

    label {
        &.fui-label {
            @include font-size($font-size-base);
            line-height: $line-height-base;
            margin-bottom: 0.75rem;

            &:not(.fui-radio-label) {
                font-weight: $font-weight-bold;
                color: $primary-grayblue;
            }
        }
    }

    textarea {
        min-height: 150px;
    }

    input,
    select,
    textarea {
        &.fui-input,
        &.fui-select {
            border: 1px solid $primary-grayblue;
            border-radius: 12px;
            padding: 10px 20px;
            @include font-size($font-size-base);
            font-weight: $font-weight-light;
            color: $primary-grayblue;

            &:focus {
                box-shadow: none;
                border: 1px solid $primary-grayblue;
            }

            &::placeholder {
                color: $primary-grayblue;
                opacity: 0.4;
            }
        }
    }

    .fui-layout-horizontal {
        .fui-layout-wrap {
            .fui-radio {
                margin-right: 30px;
            }
        }
    }

    .fui-heading {
        text-align: center;
        padding-bottom: 20px;
        border-bottom: 3px solid $primary-turquoise;
    }

    .fui-heading-h3 {
        @include font-size($font-size-base * 1.6);
        margin-top: 2rem;
        margin-bottom: 0;
    }

    .fui-required {
        color: $primary-grayblue;
    }

    .fui-submit {
        padding: 4px 100px;
        font-weight: $font-weight-bold;
        background-color: $primary-turquoise;
        border-color: $primary-turquoise;
        border-radius: 24px;
        margin-top: 20px;
    }

    .fui-type-radio-buttons {
        .fui-input-container {
            margin-left: 0;

            .fui-radio {
                label {
                    @include font-size($font-size-base);
                    line-height: $font-size-base * 1.2;
                    padding-left: 30px;
                    font-weight: $font-weight-light;

                    &::before {
                        background-image: none;
                        top: 0;
                        width: 20px;
                        height: 20px;
                        border: 1px solid $primary-grayblue;
                    }
                }

                input {
                    &:checked {
                        + {
                            label {
                                &::before {
                                    background: radial-gradient(circle, $primary-grayblue 50%, $white 50%, $white);
                                    background: -webkit-radial-gradient(circle, $primary-grayblue 50%, $white 50%, $white);
                                    border-color: $primary-grayblue;
                                }
                            }
                        }
                    }
                }
            }

            fieldset {
                legend {
                    font-weight: $font-weight-normal;
                    @include font-size($font-size-base);
                    line-height: $line-height-base;
                }
            }
        }
    }

    .fui-type-heading {
        .fui-input-container {
            margin-left: 0;
        }
    }

    .fui-checkbox {

        label {
            @include font-size($font-size-base);
            line-height: $line-height-base;
        }

        input {
            &:checked {
                + {
                    label {
                        &::before {
                            background-color: $primary-turquoise;
                            border-color: $primary-turquoise;
                            background-size: 20px auto;
                        }
                    }
                }
            }
        }

        label {
            width: 100%;
            font-weight: $font-weight-light;
            padding-left: 52px;

            &::before {
                top: 5px;
                width: $font-size-base * 1.2;
                height: $font-size-base * 1.2;
                border: 1px solid $primary-grayblue;
                border-radius: 10px;
            }
        }
    }

    .hide-label {
        legend {
            display: none;
        }
    }

    .fui-limit-text {
        display: none;
    }
}

.bg-primary-turquoise {
    .fui-form-container {
        .fui-submit {
            background-color: $primary-grayblue;
            border-color: $primary-grayblue;
        }
    }
}
