.top-bar {
  height: 33px;

  a {
    color: $white;
    @include font-size($font-size-base * .72);

    &:hover {
      color: $primary-turquoise;
    }
  }
}

#aquilana-navigation {
  position: -webkit-sticky;
  position: sticky;
  transition: top .3s ease-in-out;
  box-shadow: 0 .125rem .25rem rgba($dark, .075);
  -moz-box-shadow: 0 .125rem .25rem rgba($dark, .075);

  .navbar-brand {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    @include media-breakpoint-down(xl) {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
  }

  #logo {
    img {
      max-height: 110px;
      height: 90px;
      width: 282px;

      @include media-breakpoint-down(xl) {
        height: 70px;
        width: 219px;
      }
    }
  }

  #sticky-logo {
    max-height: 110px;
    display: none;
  }

  &.is-sticky {
    padding-bottom: 0.4rem;

    #logo {
      display: none;
    }

    #sticky-logo {
      display: block;
    }

    .navbar-brand {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .navbar-collapse {
      margin-bottom: 0.5rem !important;
    }

    .dropdown-menu {
      top: 80px;
    }

    .navbar-nav {
      .nav-item {
        .nav-link {
          line-height: 1;
        }
      }
    }
  }

  #search-link,
  #login-link {
    padding-bottom: 0;

    svg {
      height: 50px;
    }
  }

  .navbar-container {
    height: 130px;

    @include media-breakpoint-down(xl) {
      height: 80px;
    }
  }

  .navbar-brand {
    @include media-breakpoint-down(xl) {
      height: 100px;
    }

    img {
      height: 100%;
    }
  }

  .navbar-toggler {
    border: none;

    &:focus {
      box-shadow: none;
    }

    #menu-toggle {
      cursor: pointer;
      z-index: 100;

      span {
        background-color: $primary-turquoise;
        display: block;
        width: 33px;
        height: 4px;
        margin-bottom: 5px;
        position: relative;
        border-radius: 3px;
        z-index: 1;
        -webkit-transform-origin: 4px 0;
        transform-origin: 4px 0;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -webkit-background-clip: border-box;
        -webkit-text-fill-color: inherit;

        &:first-child {
          -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
        }

        &:nth-last-child(2) {
          -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
        }
      }
    }

    &:not(.collapsed) {
      #menu-toggle {
        span {
          opacity: 1;
          -webkit-transform: rotate(45deg) translate(-10px, -10px);
          transform: rotate(45deg) translate(-10px, -10px);

          &:nth-last-child(3) {
            opacity: 0;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
          }

          &:nth-last-child(2) {
            -webkit-transform: rotate(-45deg) translate(-10px, 10px);
            transform: rotate(-45deg) translate(-10px, 10px);
          }
        }
      }
    }
  }

  .navbar-nav {
    .nav-item {
      .nav-link {
        &::after {
          display: none;
        }

        .active {
          font-weight: $font-weight-normal;
        }

        &:hover {
          color: $primary-turquoise;
        }
      }
    }
  }

  .dropdown-menu {
    background-color: $secondary-smoke-blue-light;
    position: absolute;
    top: 130px;
    left: 0;
    width: 100%;
    margin-top: 0;
    border-radius: 0;
    border: none;

    li {
      &.nav-item {
        a {
          text-decoration: none;
          font-size: $font-size-sm;
          color: $primary-grayblue;

          &:hover {
            color: $primary-turquoise;
          }
        }
      }
    }
  }

  a {
    &.call-to-action-link {
      font-size: $font-size-sm;

      .call-to-action-label {
        color: $white;
      }

      &:hover {
        .call-to-action-label {
          color: $primary-grayblue;
        }
      }
    }
  }

  #mobile-navbar {
    height: calc(100vh - 80px);
    overflow: scroll;

    .mobile-nav-item-wrapper {
      .accordion-header {
        border-top: 1px solid darken($secondary-smoke-blue-light, 5%);
        font-weight: $font-weight-normal;
      }

      &:first-child {
        .accordion-header {
          border-top: none;
        }
      }

      &.bg-secondary-smoke-blue {
        .accordion-header {
          border-top: none;

          a {
            color: $primary-grayblue;
          }
        }
      }
    }

    .accordion-header {
      position: relative;

      a {
        color: $white;
        text-decoration: none;
        box-shadow: none;
        padding: 16px 0;
        width: 100%;
      }

      .accordion-button {
        width: 50px;
      }
    }

    .accordion-collapse {
      ul {
        li {
          a {
            font-size: $font-size-sm;
            color: $primary-grayblue;
            white-space: break-spaces;

            &:hover {
              color: $primary-turquoise;
              background-color: transparent;
            }
          }
        }
      }
    }

    .mobile-nav-item-wrapper {
      .accordion-item {
        border: 0;
        border-radius: 0;
        background-color: transparent;

        .accordion-button {
          border: 0;
          border-radius: 0;
          box-shadow: none;
          background-color: transparent;
          position: absolute;
          top: 0;
          right: 0;

          &:after {
            background-size: 18px;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='19.281' viewBox='0 0 20 19.281'%3E%3Cg id='Gruppe_408' data-name='Gruppe 408' transform='translate(-296.141 -7325)'%3E%3Cline id='Linie_103' data-name='Linie 103' x2='18' transform='translate(297.141 7334.641)' fill='none' stroke='%2378becd' stroke-linecap='round' stroke-width='2'/%3E%3Cline id='Linie_104' data-name='Linie 104' x2='17.281' transform='translate(306.141 7326) rotate(90)' fill='none' stroke='%2378becd' stroke-linecap='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
          }

          &:not(.collapsed) {
            &:after {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='2' viewBox='0 0 20 2'%3E%3Cline id='Linie_103' data-name='Linie 103' x2='18' transform='translate(1 1)' fill='none' stroke='%23375064' stroke-linecap='round' stroke-width='2'/%3E%3C/svg%3E");
            }
          }
        }
      }
    }

    .call-to-action-link {
      height: auto;

      br {
        display: none;
      }
    }
  }
}

.environment-info {
    font-size: 1.4rem;
    padding: 8px 0;
    position: sticky;
    top: 0;
    z-index: 1005;

    .environment-info-text {
        color: $primary-grayblue;
    }
}
