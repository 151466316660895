.doctor-card {
  .checked-circle {
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;

    &.checked {
      background: radial-gradient(circle, $primary-grayblue 50%, $white 50%, $white);
      background: -webkit-radial-gradient(circle, $primary-grayblue 50%, $white 50%, $white);
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.fui-field {
  &[data-field-handle="doctor"] {
    margin-bottom: 0;

    label,
    input {
      display: none;
    }
  }
}
