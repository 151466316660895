@import "mixins";

// scss-docs-start btn-variant-loops
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @if $color == 'secondary-smoke-blue-light' {
      @include button-variant(
        $background: $value,
        $border: $value,
        $color: $primary-grayblue,
        $hover-background: darken($value, 4%),
        $hover-border: darken($value, 4%),
        $hover-color: $primary-grayblue,
        $active-background: transparent,
        $active-border: $value,
        $active-color: $value,
      );
    } @else {
      @include button-variant(
        $background: $value,
        $border: $value,
        $color: $white,
        $hover-background: lighten($value, 5%),
        $hover-border: lighten($value, 5%),
        $hover-color: $white,
        $active-background: transparent,
        $active-border: $value,
        $active-color: $value,
        $disabled-color: $white
      );
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @if $color == 'secondary-smoke-blue-light' {
      @include button-variant(
        $background: transparent,
        $border: $value,
        $color: $value,
        $hover-background: $value,
        $hover-border: $value,
        $hover-color: $primary-grayblue,
        $active-background: lighten($value, 15%),
        $active-border: lighten($value, 15%),
        $active-color: $value
      );
    } @else {
      @include button-variant(
        $background: transparent,
        $border: $value,
        $color: $value,
        $hover-background: $value,
        $hover-border: $value,
        $hover-color: $white,
        $active-background: lighten($value, 15%),
        $active-border: lighten($value, 15%),
        $active-color: $value
      );
    }
  }
}

.accordion-button {
  @include font-size($lead-font-size);

  &:not(.collapsed) {
    box-shadow: inset 0 ($accordion-border-width * -1) 0 $accordion-border-color;
  }
}

.accordion-item {
  border-top: $accordion-border-width solid $accordion-border-color;
  border-right: unset;
  border-bottom: $accordion-border-width solid $accordion-border-color;
  border-left: unset;
}

.lead {
  line-height: 1.53;
}

.shadow-md {
  box-shadow: 0 0.2rem 0.4rem rgba(0,0,0,0.08) !important;
}
