.block-insurance-card {
  .insurance-card-icon-wrapper {
    min-width: 96px;
    max-width: 96px;
    width: 96px;

    svg {
      width: 100%;
      height: auto;
    }
  }
}
