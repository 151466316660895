.block-faqs {
  .accordion-item {
    background-color: transparent;

    &:last-of-type {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      .accordion-collapse {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &:first-of-type {
      border-top-right-radius: 0;
      border-top-left-radius: 0;

      .accordion-collapse {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }

    .accordion-button {
      padding-left: 0;
      padding-right: 0;
      background-color: transparent;
      border-radius: 0;

      &:after {
        background-image: url('/templates/_assets/images/plus-primary-grayblue.svg');
      }

      &:not(.collapsed) {
        background-color: transparent;

        &:after {
          background-image: url('/templates/_assets/images/minus-primary-grayblue.svg');
        }
      }
    }
  }
}

.bg-primary-grayblue {
  .block-faqs {
    .accordion-item {
      border-color: $white;
    }

    .accordion-button {
      &:after {
        background-image: url('/templates/_assets/images/plus-white.svg');
      }

      &:not(.collapsed) {
        box-shadow: inset 0 -1px 0 $white;

        &:after {
          background-image: url('/templates/_assets/images/minus-white.svg');
        }
      }
    }
  }
}
