@import 'node_modules/swiper/swiper.scss';
@import 'node_modules/swiper/modules/pagination/pagination';
@import 'node_modules/swiper/modules/effect-fade/effect-fade';

.slider-layout {
  .swiper-wrapper {
    margin-bottom: 40px;
  }

  .swiper-pagination {
    pointer-events: none;
    bottom: -5px;
  }

  .swiper-pagination-bullet {
    pointer-events: all;
    opacity: 1;
    background: transparent;
    border: 1px solid $primary-turquoise;
    width: $spacer;
    height: $spacer;
    border-radius: 50rem;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: $primary-turquoise;
  }
}
