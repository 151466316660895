.table,
.table-striped {
    width: 100%;

    .sticky-col {
        border-left-width: 1px;
        position: -webkit-sticky;
        position: sticky;
        min-width: 100px;
        width: 100px;
        left: 0;
        z-index: 10;
    }

    tr {
        td {
            table {
                tr {
                    td {
                        padding: 8px 32px;

                        &:first-child {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }

    .table-content-column {
        @include media-breakpoint-down(sm) {
            min-width: 250px;
        }
    }
}
