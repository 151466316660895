.fast-controls-wrapper {
  position: fixed;
  bottom: 120px;
  right: 120px;
  z-index: 1000;

  @include media-breakpoint-up(lg) {
    bottom: 150px;
    right: 150px;
  }
}

.fast-control {
  cursor: pointer;
  width: 92px;
  height: 92px;
  border-radius: 50%;
  z-index: 1050;
  position: absolute;
  top: 0;
  left: 0;

  &.move-up-0 {
    top: -140px;
  }

  &.move-up-1 {
    top: -100px;
    left: -100px
  }

  &.move-up-2 {
    left: -140px;
  }
}

.fast-control-item {
  box-shadow: 0 0 7px 2px rgba(0,0,0,0.16);

  @include media-breakpoint-up(lg) {
    box-shadow: none;
    border: 1px solid $white;

    @for $i from 0 through 2 {
      &:nth-child(#{$i}) {
        top: #{$i * -110}px
      }
    }
  }
}

#main-fast-control {
  z-index: 1100;
  box-shadow: 0 0 7px 2px rgba(0,0,0,0.16);

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.fast-control-item-ease-in-out {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
