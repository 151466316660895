// Utilities
$enable-negative-margins: true;

// Typography
$font-size-root: 20px;
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2.6;
$h2-font-size: $font-size-base * 1.6;
$h3-font-size: $font-size-base * 1.4;
$h4-font-size: $font-size-base * 1.2;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;
$lead-font-size: $font-size-base * 1.2;

$font-family-sans-serif: "gt-walsheim", system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-family: "gt-walsheim", system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$small-font-size: .8em;

$font-weight-light: 300;
$font-weight-bold: 700;
$font-weight-base: $font-weight-light;
$lead-font-weight: $font-weight-light;
$headings-font-weight: $font-weight-bold;

// Colors
$body-color: $primary-grayblue;
$theme-colors: (
    "primary": $primary-turquoise,
    "primary-turquoise": $primary-turquoise,
    "primary-grayblue": $primary-grayblue,
    "secondary": $secondary-smoke-blue,
    "secondary-smoke-blue": $secondary-smoke-blue,
    "secondary-smoke-blue-light": $secondary-smoke-blue-light,
    "white": $white,
    "light-gray": $light-gray,
    "beige": #b0a393,
    "dark": $dark,
    "dark-light": $dark-light,
    "info": $info,
    "success": $success,
    "warning":$warning,
    "error":$error
);

$primary: $primary-turquoise;
$secondary: $secondary-smoke-blue;

$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    6: $spacer * 4,
    7: $spacer * 5,
);

$enable-cssgrid: true;

$aspect-ratios: (
    "1x1": 100%,
    "333x173": calc(173.88 / 333.33 * 100%),
    "4x3": calc(3 / 4 * 100%),
    "16x9": calc(9 / 16 * 100%),
    "21x9": calc(9 / 21 * 100%),
    "32x9": calc(9 / 31 * 100%),
);

// Buttons
$btn-border-radius: $font-size-base * 1.2;
$btn-border-width: 2px;
$btn-font-weight: $font-weight-bold;
$btn-padding-x: map-get($spacers, 3);
$btn-padding-y: $spacer * .15;
$btn-disabled-opacity: .2;
$btn-font-size: $font-size-base * .8;

// Links
$link-color: $primary-grayblue;
$link-hover-color: $primary-turquoise;
$link-hover-decoration: underline;

// Navbar
$navbar-light-color: $primary-grayblue;

// Carousel
$btn-close-opacity: 1;
$btn-close-hover-opacity: 1;
$carousel-control-opacity: 1;
$carousel-control-hover-opacity: 1;
$carousel-control-color: $white;
$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
