$primary-turquoise: #78BECD;
$primary-grayblue: #375064;
$secondary-smoke-blue: #B3C0CC;
$secondary-smoke-blue-light: #EFF2F5;

$white: #ffffff;
$light-gray: #E2E6E7;
$dark: #000000;
$dark-light: #353839;

$info: #6180BC;
$success: #488B60;
$warning: #F0BC47;
$error: #D24345;
