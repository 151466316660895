.choices {
  .choices__inner {
    border: 1px solid $primary-grayblue;
    border-radius: 12px;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 300;
    color: #375064;

    .choices__list {
      padding: 0;
    }
  }

  .choices__list--dropdown {
    border: 1px solid $primary-grayblue !important;
    border-radius: 12px;
    font-size: 1rem;
    font-weight: 300;
  }

  .choices__input--cloned {
    border: none !important;
    border-radius: 0;
    outline: none !important;
    border-bottom: 1px solid $primary-grayblue !important;
  }

  .choices__list--dropdown {
    .choices__item {
      font-size: 0.9rem;
    }
  }
}

