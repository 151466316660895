.block-cards {
    .block-body {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 0;
        }
    }

    .card-subtitle {
        @include font-size($font-size-base * 0.8);
    }

    .card-icon-wrapper {
        padding-top: 2rem;
        padding-bottom: 0.5rem;
    }

    .card-icon {
        width: 60px;
        height: 60px;
    }

    .card-link-icon {
        top: 2px;
    }

    .clickable-card {
        > div {
            transition: box-shadow 200ms ease-in-out;

            > div {
                img {
                    -webkit-transition: transform 100ms ease-in-out;
                    -moz-transition: transform 100ms ease-in-out;
                    -ms-transition: transform 100ms ease-in-out;
                    -o-transition: transform 100ms ease-in-out;
                    transition: transform 100ms ease-in-out;
                }
            }
        }

        &:hover {
            > div {
                box-shadow: 0 4px 30px 0 rgba(120, 190, 205, 0.5) !important;

                > div {
                    img {
                        transform: scale(1.05);
                    }
                }
            }

            .btn.btn-outline-primary-grayblue {
                background-color: $primary-grayblue;
                color: $white;
            }
        }
    }

}
