* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  background-color: $body-bg-color;
}

body {
  margin-right: auto;
  margin-left: auto;
  max-width: $body-max-with;

  &.menu-open {
    max-height: 100vm;
    overflow: hidden;
  }
}

.p2 strong {
    font-weight: bold;
}

.half-container {
  width: 100%;

  @include media-breakpoint-up(sm) {
    max-width: 540px;
  }

  @include media-breakpoint-up(md) {
    max-width: 720px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 960px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 570px;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 660px;
  }
}

.no-last-child-margin-bottom {
  * {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

li {
  margin-bottom: .6rem;

  &::marker {
    color: $primary-turquoise;
  }
}

.bg-primary-turquoise {
  li {
    &::marker {
      color: $white;
    }
  }
}

.wysiwyg {
  ul,
  ol {
    padding-left: $font-size-base;

    li {
      margin-bottom: .6rem;

      &::marker {
        color: $primary-turquoise;
      }
    }
  }
}

.bg-primary-turquoise {
  .wysiwyg {
    li {
      &::marker {
        color: $primary-grayblue;
      }
    }
  }
}


.bg-secondary-smoke-blue {
  .wysiwyg {
    a {
      &:hover {
        color: $white;
      }
    }
  }
}

a {
  &.no-link-style {
    @include link-unset;
  }
}

.no-last-child-margin-bottom {
  * {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.no-last-child-border-bottom {
  * {
    &:last-child {
      border-bottom: none !important;
    }
  }
}

ul, ol {
  &.no-list-style {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

.object-fit-cover {
  object-fit: cover !important;
}

.fa-xl {
  line-height: 1.5em;
}

.rounded-social-link {
  display: inline-block;
  height: 45px;
  width: 45px;
  font-size: 30px;
  line-height: 45px;
  border: 2px solid $primary-turquoise;
  border-radius: 50%;
}

div[class^="multi-column"], div[class*="multi-column"] {
  img {
    max-width: 100%;
  }
}

@each $color, $value in $theme-colors {
  .icon-#{$color} {
    path {
      fill: $value;
    }
  }

  i {
    &.icon-#{$color} {
      color: $value;
    }
  }
}

.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

p {
  @include font-size($font-size-base);
  line-height: 1.5;
}

.p2,
.p2 * {
  @include font-size($lead-font-size);
  font-weight: $font-weight-light;
  line-height: 1.4;
}

.p3,
.p3 * {
  @include font-size($lead-font-size * 0.8);
  font-weight: $font-weight-light;
  line-height: 1.1;
}

.bg-primary-grayblue, .bg-primary-turquoise {
  span {
    &.redactor-link {
      a, em {
        color: $white;
      }
    }
  }
}

.secondary-smoke-blue {
  span {
    &.redactor-link {
      em {
        color: $white;
      }
    }
  }
}

.w-xl-50 {
  @include media-breakpoint-up(xl) {
    width: 50% !important;
  }
}

.h-xl-100 {
  @include media-breakpoint-up(xl) {
    height: 100% !important;
  }
}

.left-xl-50 {
  @include media-breakpoint-up(xl) {
    left: 50% !important;
  }
}

.top-xl-0 {
  @include media-breakpoint-up(xl) {
    top: 0 !important;
  }
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

#klaro {
  .cookie-notice {
    padding: 1.2rem;

    p {
      color: $primary-grayblue;
    }

    .cm-link {
      color: $primary-turquoise ;
      @include font-size($font-size-base);
    }
  }

  .cm-modal {
    padding: 1.5rem;

    .slider {
      box-shadow: none;
      border: 1px solid $primary-grayblue;
      background-color: transparent;

      &::before {
        background-color: $primary-grayblue;
        height: 24px;
        width: 24px;
        bottom: 2px;
        left: 2px;
      }
    }

    .cm-list-input {
      &:checked {
        +.cm-list-label {
          .slider {
            background-color: $primary-grayblue;

            &::before {
              background-color: $white;
            }
          }
        }
      }
    }

    .cm-header {
      h1 {
        &.title {
          color: $primary-grayblue;
        }
      }
    }

    p {
      color: $primary-grayblue;

      a {
        color: $primary-turquoise;
        @include font-size($font-size-base);
      }
    }

    .cm-body {
      li {
        &.cm-purpose {
          @include font-size($font-size-base);

          .cm-caret {
            a {
              color: $primary-turquoise;
            }
          }
        }
      }
    }

    .cm-footer {
      .cm-powered-by {
        a {
          @include font-size($font-size-base * 0.6);
        }
      }
    }
  }

  .cn-ok {
    justify-content: left;
  }

  .cm-btn {
    border: 1px solid $primary-grayblue;
    background-color: transparent;
    color: $primary-grayblue;
    padding: 0.15rem 1rem;
    border-radius: 1.2rem;
    @include font-size($font-size-base * 0.8);

    &.cm-btn-success {
      border: 1px solid $primary-turquoise;
      background-color: $primary-turquoise;
      color: $white;
    }
  }
}


