@mixin hero-ratios() {
  --bs-aspect-ratio: #{map-get($aspect-ratios, '1x1')};

  @include media-breakpoint-up(sm) {
    --bs-aspect-ratio: #{map-get($aspect-ratios, '16x9')};
  }

  @include media-breakpoint-up(xl) {
    --bs-aspect-ratio: #{map-get($aspect-ratios, '32x9')};
  }
}

@mixin hero-limited-ratios() {
  --bs-aspect-ratio: #{map-get($aspect-ratios, '1x1')};

  @include media-breakpoint-up(sm) {
    --bs-aspect-ratio: #{map-get($aspect-ratios, '16x9')};
  }
}

.hero-ratios {
  @include hero-ratios;
}

.hero-limited-ratios {
  @include hero-limited-ratios;
}

@mixin link-unset(
  $color: unset,
  $border-bottom: unset,
  $background-color: unset,
  $padding-bottom: unset,
  $hover-color: unset,
  $transition: unset,
  $text-decoration: unset,
  $content-before: unset,
  $content-after: unset,
  $hover-border-bottom: unset,
  $hover-background-color: unset,
  $hover-padding-bottom: unset,
  $hover-transition: unset,
  $hover-text-decoration: unset,
) {
  color: $color;
  border-bottom: $border-bottom;
  background-color: $background-color;
  padding-bottom: $padding-bottom;
  transition: $transition;
  text-decoration: $text-decoration;

  &:hover {
    color: $hover-color;
    border-bottom: $hover-border-bottom;
    background-color: $hover-background-color;
    padding-bottom: $hover-padding-bottom;
    transition: $hover-transition;
    text-decoration: $hover-text-decoration;
  }

  &::before {
    content: $content-before;
  }

  &::after {
    content: $content-after;
  }
}
